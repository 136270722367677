import {createRouter, createWebHistory} from 'vue-router'
import store from "@/store";

const HomeView = () => import(/* webpackChunkName:"HomeView" */ '@/views/HomeView.vue');
const CrawlView = () => import(/* webpackChunkName:"CrawlView" */ '@/views/CrawlView.vue');
const UserView = () => import(/* webpackChunkName:"UserView" */ '@/views/UserView.vue');
const JobFormView = () => import(/* webpackChunkName:"SkuSelectView" */ '@/views/JobFormView.vue');
const LoginView = () => import(/* webpackChunkName:"LoginView" */ '@/views/LoginView.vue');
const PreviewChangeView = () => import(/* webpackChunkName:"PreviewChangeView" */ '@/views/PreviewChangeView.vue');
const PreCrawlView = () => import(/* webpackChunkName:"PreCrawlView" */ '@/views/PreCrawlView.vue');
const JobResultView = () => import(/* webpackChunkName:"JobResultView" */ '@/views/JobResultView.vue');

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {title: "Home", appearOnNav: false, allowGuest: true},
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: {title: "Login", isAuth: true, allowGuest: true},
    },
    {
        path: '/job/:id/crawl',
        name: 'crawl',
        component: CrawlView,
        meta: {title: "Crawl", appearOnNav: false},
    },
    {
        path: '/job',
        name: 'job-create',
        component: JobFormView,
        meta: {title: "Create New Job", appearOnNav: false},
    },
    {
        path: '/job/:id',
        name: 'job-edit',
        component: JobFormView,
        meta: {title: "Edit Job", appearOnNav: false},
    },
    {
        path: '/job/:id/final',
        name: 'submit-preview',
        component: PreviewChangeView,
        meta: {title: "Submit Preview", appearOnNav: false},
    },
    {
        path: '/users',
        name: 'users',
        component: UserView,
        meta: {title: "Manage Users", appearOnNav: true, allowGuest: false, requireAdmin: true},
    },
    {
        path: '/job/:id/before-crawl',
        name: 'before-crawl',
        component: PreCrawlView,
        meta: {title: "Crawl Input", appearOnNav: false},
    },{
        path: '/job/:id/job-results',
        name: 'job-results',
        component: JobResultView,
        meta: {title: "View Job Result", appearOnNav: false},
    },{
        path: '/job/:id/job-results/:resultId',
        name: 'job-results-view',
        component: CrawlView,
        meta: {title: "View Job Result", appearOnNav: false},
    },{
        path: '/job/:id/job-results/:resultId/final',
        name: 'job-results-view-final',
        component: PreviewChangeView,
        meta: {title: "View Job Result", appearOnNav: false},
    }
]

const router = createRouter({
    history: createWebHistory(),
    beforeEach: (to, from, next) => {
        if (to.matched.some(route => !route.meta.allowGuest)) {
            if (store.getters.isLoggedIn) {
                next()
            } else {
                next('/login')
                next({name: 'login', params: {from: to.path} })
            }
        }
    },
    // This function is to update title with the one from meta.title declared in router/index.js
    afterEach: (to) => {
        document.title = to.meta.title;
    },
    routes
})


export default router
