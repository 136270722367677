import api from "@/api";

export function login(username, password, callback) {
    let params = {username: username, password: password}
    return api.send('/api/auth', "login", params, callback)
}

export function logout(callback) {
    return api.send('/api/auth', "logout", {}, callback)
}

export function getUsers(callback) {
    return api.send(api.URLS.USER_VIEW, "getUsers", {}, callback)
}

export function deleteUser(username, callback) {
    let params = { username: username }
    return api.send(api.URLS.USER_VIEW, "deleteUser", params, callback)
}

export function updateUserPassword(username, password, callback) {
    let params = {username: username, password: password}
    return api.send(api.URLS.USER_VIEW, "updatePassword", params, callback)
}

export function createUser(newUserForm, callback) {
    return api.send(api.URLS.USER_VIEW, "createUser", {user: newUserForm}, callback)
}

export function updateUser(editUserForm, callback) {
    return api.send(api.URLS.USER_VIEW, "updateUser", {user: editUserForm}, callback)
}

export function renewOitm(callback) {
    return api.send(api.URLS.SAP_VIEW, "renewOitm", {}, callback)
}

export function getSkusSelectInitData(callback) {
    return api.send(api.URLS.SAP_VIEW, "getSkusSelectInitData", {}, callback)
}

export function getSkus(filter, sort, callback) {
    return api.send(api.URLS.SAP_VIEW, "getSkusPaginated", {filter, sort}, callback)
}

export function getSkusFromList(skus, callback) {
    return api.send(api.URLS.SAP_VIEW, "getSkusFromList", {skus}, callback)
}

export function getMyJobs(callback) {
    return api.send(api.URLS.JOB_VIEW, "getMyJobs", {}, callback)
}

export function saveMyJob(newJobForm, callback) {
    return api.send(api.URLS.JOB_VIEW, "saveJob", newJobForm, callback)
}

export function removeJob(id, callback) {
    return api.send(api.URLS.JOB_VIEW, "removeJob", {id}, callback)
}

export function getJobDetails(id, callback) {
    return api.send(api.URLS.JOB_VIEW, "jobDetails", {id: id}, callback)
}

export function getPreCrawlDataByUser(skus = null, callback) {
    return api.send(api.URLS.PROFILE_VIEW, "getPreCrawlDataByUser", {skus}, callback)
}

export function saveUserProfiles(itemConfigs, callback) {
    return api.send(api.URLS.PROFILE_VIEW, "saveUserProfiles", {itemConfigs: itemConfigs}, callback)
}

export function crawl(keywords, scopes, callback) {
    return api.send(api.URLS.CRAWL_VIEW, "crawl", {keywords, scopes}, callback)
}

export function getSellThroughFromSku(sku, callback) {
    return api.send(api.URLS.SAP_VIEW,"getSellThroughFromSku", {sku}, callback)
}

export function getCommentsFromSkuList(skus, callback) {
    return api.send(api.URLS.COMMENT_VIEW,"getCommentsFromSkuList", {skus}, callback)
}

export function batchUpdateComments(comments, callback) {
    return api.send(api.URLS.COMMENT_VIEW,"batchUpdateComments", {comments}, callback)
}

export function updateComment(comment, sku, callback) {
    return api.send(api.URLS.COMMENT_VIEW,"updateComment", {comment, sku}, callback)
}

export function sendEmail(jobName, result, callback) {
    return api.send(api.URLS.EMAIL_VIEW,"mail", {jobName, result}, callback)
}

export function saveJobResult(session, callback) {
    return api.send(api.URLS.JOB_VIEW, "saveJobResult", {session}, callback)
}

export function getJobById(id, callback) {
    return api.send(api.URLS.JOB_VIEW, "getJobById", {id}, callback)
}

export function getResultData(jobId, resultId, callback) {
    return api.send(api.URLS.JOB_VIEW, "getResultData", {jobId, resultId}, callback)
}

export function deleteJobResult(jobId, resultId, callback) {
    return api.send(api.URLS.JOB_VIEW, "deleteJobResult", {jobId, resultId}, callback)
}