<template>
	<nav class="navbar navbar-light navbar-expand-lg bg-light">
		<div class="container-fluid">
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
				aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<router-link class="navbar-brand h1 mb-0" to="/">
				LV Crawl
			</router-link>
			<div class="collapse navbar-collapse">
				<ul class="navbar-nav me-auto">
					<li class="nav-item">
						<!-- This is for the progress numbers in CrawlView using Vue 3 newest tech: Portal (Thinking with it...) -->
						<span id="crawl-progress"></span>
					</li>
				</ul>
				<!-- NAVBAR RIGHT SIDE -->
				<ul class="navbar-nav ms-auto">
					<template v-if="getStoreIsLoggedIn">
						<renew-sap-data v-if="getStoreUser.isSuperUser" />
						<li class="nav-item">
							<span class="nav-link">
								G'day {{ getStoreUser.username }}
							</span>
						</li>
					</template>
					<template v-for="(route, i) in allRoutes" :key="i">
						<li class="nav-item" v-if="shouldShowInNav(route)">
							<router-link class="nav-link" :to="{name: route.name}">{{ route.meta.title }}</router-link>
						</li>
					</template>
					<!-- IF USER IS NOT LOGGED IN -->
					<li class="nav-item" v-if="!getStoreIsLoggedIn">
						<router-link class="nav-link" :to="authRoute.name">{{ authRoute.meta.title }}</router-link>
					</li>
					<li class="nav-item" v-else>
						<a class="nav-link" href="javascript:void(0);" @click="logout">Logout</a>
					</li>
				</ul>
			</div>
		</div>
	</nav>
</template>

<script>

import {logout} from "@/services/DataService";
import ToasterService from "@/services/ToasterService";
import {store} from "@/mixins/store";
import RenewSapData from "@/components/RenewSapData";

export default {
	name: "NavigationComponent",
	components: {RenewSapData},
	mixins: [store],
	methods: {
		shouldShowInNav(route) {
			if(route.meta.allowGuest) {
				return route.meta.appearOnNav
			} else if(route.meta.requireAdmin) {
				return route.meta.appearOnNav && this.getStoreIsLoggedIn && this.getStoreUser.isSuperUser
			} else {
				return route.meta.appearOnNav && this.getStoreIsLoggedIn
			}
		},
		logout() {
			logout((e, r) => {
				if (r.success) {
					// Logic to delete session
					this.$store.commit('logout')
					ToasterService.success("Logout completed!")
					this.$router.push({name: "home"})
				}
			})
		}
	},
	computed: {
		allRoutes() {
			return this.$router.options.routes;
		},
		authRoute() {
			return this.allRoutes.find(route => {
				return route.meta.isAuth;
			})
		},
	}
}
</script>

<style scoped>

</style>