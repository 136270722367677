import {createStore} from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
    state: {
        user: null,
        crawlSession: null,
    },
    getters: {
        getJobName(state) {
            return state.jobName
        },
        isLoggedIn(state) {
            return Boolean(state.user)
        },
        getUser(state) {
            return state.user
        },
        getCrawlSession(state) {
            return state.crawlSession
        },
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload.user
        },
        setJobName(state, payload) {
            state.jobName = payload.newJobName
        },
        logout(state) {
            delete state.user
        },
        setSelectedCrawl(state, payload) {
            state.crawlSession.selectedCrawl = payload.selectedCrawl
        },
        setCrawlResult(state, payload) {
            state.crawlSession.crawlResult = payload.crawlResult
        },
        setCrawledPrices(state, payload) {
            state.crawlSession.crawledPrices = payload.crawledPrices
        },
        setCrawlSession(state, payload) {
            state.crawlSession = payload.crawlSession
        },
        clearCrawlSession(state) {
            delete state.crawlSession
        }
    },
    actions: {},
    modules: {},
    plugins: [
        createPersistedState({ storage: window.sessionStorage })
    ],
})
