<template>
	<button class="btn btn-info" @click="renewSAPData">
		<loading-spinner v-if="loading" class-name="spinner-border-sm d-inline m-0" size="1"/>
		Renew SAP/Magento Data
	</button>
</template>

<script>
import {renewOitm} from "@/services/DataService";
import ToasterService from "@/services/ToasterService";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
	name: "RenewSapData",
	components: {LoadingSpinner},
	data() {
		return {
			loading: false,
		}
	},
	methods: {
		renewSAPData() {
			if (confirm("Are you sure you want to renew SAP/Magento data?")) {
				this.loading = true
				renewOitm((e, r) => {
					if(r.success) {
						ToasterService.success("SAP data renewed successfully");
					} else {
						ToasterService.error("Oh crab! " + e.data.error);
					}
					this.loading = false
				})
			}
		}
	}
}
</script>

<style scoped>

</style>