import Toastify from 'toastify-js'

export default class ToasterService {
    static toast(message, type) {
        if(["info", "success", "error"].indexOf(type) > -1) {
            let colors = {
                "info": "linear-gradient(to right, #337ab7, #468ecb)",
                "success": "linear-gradient(to right, #43a047, #53b757)",
                "error": "linear-gradient(to right, #e57373, #d93131)"
            };

            let icons = {
                "info": "mdi-information",
                "success": "mdi-check-circle",
                "error": "mdi-alert-circle"
            };

            let template = document.createElement("template");
            template.innerHTML = `<div class="toastify-content"><i class="mdi ${icons[type]}" style="padding-right: 5px"></i><span>${message}</span></div>`;

            Toastify({
                duration: 3000, // 3 seconds before the notification disappear,
                close: true, // Show the close button
                gravity: "top", // Where the toast message will be `top` or `bottom`
                position: "center", // Where the toast message will be `left`, `center` or `right`
                stopOnFocus: true, // Prevents dismissing of toast on hover
                node: template.content.firstChild,
                style: {
                    background: colors[type], // Show the correct color based on the type
                }
                // offset: {
                //     y: 50, // horizontal axis - can be a number or a string indicating unity
                // },
            }).showToast();
        } else {
            alert("Wrong type sir! Had to be info, success or error");
        }
    }

    static info(message) {
        this.toast(message, "info");
    }

    static success(message) {
        this.toast(message, "success");
    }

    static error(message) {
        this.toast(message, "error");
    }
}