<template>
	<div class="justify-content-center" :class="className">
		<div class="spinner-border text-primary" role="status" :style="{width: `${this.size}rem`, height: `${this.size}rem`}">
			<span class="visually-hidden">Loading...</span>
		</div>
	</div>
</template>

<script>
export default {
	name: "LoadingSpinner",
	props: {
		className: {
			type: String,
			default: "m-5 d-flex"
		},
		size: {
			type: Number,
			default: 2
		},
	},
}
</script>

<style scoped>

</style>