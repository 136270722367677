<template>
	<navigation />
		<router-view v-slot="{ Component, route }">
			<transition name="fade" mode="out-in">
				<component :is="Component" :key="route.path" />
			</transition>
		</router-view>
</template>

<script>
	import Navigation from "@/components/Navigation"

	export default {
		name: "App",
		components: {
			Navigation
		}
	}
</script>