import axios from 'axios'

const api = {
    URLS: {
        AUTH_VIEW: "/api/auth",
        EBAY_VIEW: "/api/ebay",
        STATICICE_VIEW: "/api/staticice",
        KOGAN_VIEW: "/api/kogan",
        CATCH_VIEW: "/api/catch",
        GOOGLE_VIEW: "/api/google",
        AMAZON_VIEW: "/api/amazon",
        JOB_VIEW: "/api/job",
        SAP_VIEW: "/api/sap",
        CONFIG_VIEW: "/api/config",
        PROFILE_VIEW: "/api/profile",
        USER_VIEW: "/api/user",
        COMMENT_VIEW: "/api/comment",
        CRAWL_VIEW: "/api/crawl",
        EMAIL_VIEW: "/api/email",
    },
    send: (url, action, data = {}, callback) => {
        data.action = action
        return axios.post(url, data).then(response => {
            callback(null, response.data);
        }).catch(error => {
            if(error.response && error.response.status) {
                callback(error.message ?? error, error.response.data);
            } else {
                callback(error, error);
            }
        })
    }
}

export default api