import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from './store'

import "bootstrap";
import "./assets/styles/main.scss";
import '@popperjs/core';
// Sweetalert 2 (Vue wrapper)
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import ToasterService from "@/services/ToasterService";

const swalOptions = {
    reverseButtons: true, // So the confirm button will be in the right side
    confirmButtonColor: '#198754',
    denyButtonColor: '#dc3545',
    cancelButtonColor: '#d63384'
}

// Handling the 401 error, redirect to login
axios.interceptors.response.use(undefined, (error) => {
    if(error) {
        const originalReq = error.config
        if(error.response.status === 401 && !originalReq._retry) {
            ToasterService.info("Wait up brah, please login first!")
            originalReq._retry = true
            store.commit('logout')
            return router.replace({name: 'login', query: {from: window.location.pathname}})
        }
    }
})
// export const bus = new Vue(); // Global event bus



createApp(App)
    .use(store)
    .use(router)
    .use(VueSweetalert2, swalOptions)
    .mount('#app')
